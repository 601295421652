import { Switch, Match, Show } from 'solid-js';
import { dayTimeToDate, formatDateTime, formatDateTimeAttribute } from '../modules/date-formatting';
import type { CalendarDayTime, Currency } from '../graphql';

type DayTimeProps = {
	cancelDayTime: CalendarDayTime;
	cancellationThresholdInHours?: never;
};
type ThresholdProps = {
	cancelDayTime?: never;
	cancellationThresholdInHours: number;
};
type NoFeeProps = {
	cancelDayTime?: never;
	cancellationThresholdInHours?: never;
};
type Props = {
	cancelFee?: Currency;
} & (DayTimeProps | ThresholdProps | NoFeeProps);

export function CancellationPolicy(props: Props) {
	return (
		<Switch>
			<Match when={props.cancellationThresholdInHours}>
				You can cancel up until{' '}
				<Switch>
					<Match when={props.cancelDayTime}>
						<time datetime={formatDateTimeAttribute(dayTimeToDate(props.cancelDayTime!))}>
							{formatDateTime(dayTimeToDate(props.cancelDayTime!))}
						</time>
					</Match>
					<Match when={props.cancellationThresholdInHours}>
						{props.cancellationThresholdInHours} hours before the tee time
					</Match>
				</Switch>
				.
				<Show when={props.cancelFee}>
					{' '}
					Otherwise the cancellation fee is {props.cancelFee!.displayValue} per golfer.
				</Show>
			</Match>
			<Match when={props.cancelDayTime}>
				You can cancel up until{' '}
				<time datetime={formatDateTimeAttribute(dayTimeToDate(props.cancelDayTime!))}>
					{formatDateTime(dayTimeToDate(props.cancelDayTime!))}
				</time>{' '}
				for no fee.
				<Show when={props.cancelFee}>
					{' '}
					Otherwise the cancellation fee is {props.cancelFee!.displayValue} per golfer.
				</Show>
			</Match>
		</Switch>
	);
}
